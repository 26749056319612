/* src/LandingPage.css */
.landing-page {
  @apply flex flex-col items-center justify-center h-screen bg-cover bg-no-repeat bg-center bg-purple-app;
  height: 100vh;
  background-image: url("../static/landing_background_sm.svg");
}

.landing-content {
  @apply w-full text-center px-6;
}

.landing-title {
  @apply text-4xl text-center font-bold text-white mb-1 tracking-tightest cursor-none;
}

.landing-description {
  @apply text-3xl text-center text-white my-8 tracking-tightest cursor-none;
}

.landing-register-btn {
  @apply bg-orange-app text-white font-bold py-2 px-6  rounded-lg text-2xl hover:bg-green-app transition duration-300 ease-in-out cursor-pointer;
}

.landing-welcome-text {
  @apply text-2xl font-bold text-center text-white mt-6 tracking-tightest cursor-none;
}

/* Sección del video */
.landing-video {
  @apply py-16 bg-white flex flex-col items-center justify-stretch;
}

.more-info-title {
  @apply text-6xl font-bold text-orange-app mb-6 mt-0;
}

.video-container {
  @apply w-3/4 mt-8 lg:mt-0 flex justify-center;
}

.video {
  @apply w-full shadow-lg rounded-lg;
}

/* Sección con imagen de fondo y contenedores de texto */
.landing-info {
  @apply relative flex items-center py-20 px-10 bg-cover bg-center font-bold;
  height: 70vh;
}

.bg-style-1 {
  background-image: url("../static/bg-style1.svg");
  @apply text-purple-app justify-start text-5xl;
}

.bg-style-2 {
  background-image: url("../static/bg-style2.svg");
  @apply text-purple-app justify-end text-6xl;
}

.bg-style-3 {
  background-image: url("../static/bg-style3.svg");
  @apply text-purple-app justify-end text-6xl;
}

.bg-style-4 {
  background-image: url("../static/bg-style4.svg");
  height: 60vh;
  @apply text-white text-center text-8xl;
}

.bg-style-5 {
  background-image: url("../static/bg-style5.svg");
  height: 70vh;
  @apply text-white text-center justify-end text-4xl  bg-orange-app;
}

.bg-style-6 {
  background-image: url("../static/bg-style6.svg");
  @apply text-purple-app justify-start text-8xl;
}

.info-main-text {
  @apply p-8 tracking-tightest;
}

.on-left {
  @apply w-3/5 text-left;
}

.on-right {
  @apply w-3/5 text-right;
}

.on-center {
  @apply w-full text-center;
}

/* Sección de las tarjetas */
.landing-cards {
  @apply py-16 px-6 bg-purple-app flex flex-col lg:flex-row justify-around items-center space-y-8 lg:space-y-0 lg:space-x-6;
  height: fit-content;
  background-image: url("../static/bg-style7.svg");
}

.card {
  @apply text-center flex flex-col items-center text-xl lg:w-1/4 tracking-tightest;
  width: 80vw;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card:hover {
  @apply transform scale-105;
}

.card h1 {
  @apply font-bold mb-4  text-orange-app;
  text-shadow: 4px 4px 6px rgba(171, 171, 171, 0.5);
}

.card p {
  @apply flex items-center text-3xl  bg-white  text-purple-app font-bold p-6 mx-2 rounded-lg shadow-lg;
  height: 30vh;
}

.bg-style-8 {
  @apply text-purple-app justify-start text-8xl;
}

.landing-experience {
  @apply flex flex-col items-center justify-center py-16 bg-gray-100 space-y-6 w-full bg-cover bg-no-repeat;
  height: 70vh;
  background-image: url("../static/bg-style8.svg");
}

.experience-title {
  @apply text-6xl lg:text-8xl font-bold text-center text-purple-app my-8 tracking-tightest w-3/4;
}

.experience-step {
  @apply text-2xl lg:text-3xl text-white font-medium py-4 px-4 rounded-tr-full shadow-md w-2/5 text-center bg-purple-app tracking-tighter;
  transition: margin-left 0.3s ease-in-out;
}

.experience-step:hover {
  @apply transform translate-x-4 bg-light-purple-app border-b-4 border-orange-app transition-all duration-300 ease-in-out;
}

.experience-step:nth-child(2) {
  @apply bg-light-purple-app;
}

.experience-step:nth-child(3) {
  @apply bg-purple-app;
}

.experience-step:nth-child(4) {
  @apply bg-light-purple-app;
}

/* Responsive design for large screens*/
@media (min-width: 1024px) {
  .landing-cards {
    @apply flex-wrap;
  }

  .card {
    @apply w-2/5;
  }

  .card h1 {
    @apply text-9xl mb-6;
  }

  .card p {
    @apply text-center;
  }

  .landing-page {
    @apply bg-left;
    background-image: url("../static/landing_background_lg.svg");
    background-attachment: fixed;
  }

  .landing-content {
    @apply w-2/5  ml-auto;
  }

  .landing-title {
    @apply text-6xl text-left mt-0;
  }

  .landing-description {
    @apply text-5xl text-left;
  }

  .landing-welcome-text {
    @apply w-2/5 absolute bottom-4 left-4 text-left;
  }
}

/* Responsive design for small screens*/
@media (max-width: 1024px) {
  .landing-experience {
    height: fit-content;
  }

  .experience-step {
    @apply w-3/4 rounded-t-full;
  }

  .card {
    @apply flex-row items-center text-left;
  }

  .card h1 {
    @apply text-7xl mr-4;
  }

  .card p {
    @apply text-left text-xl;
    height: fit-content;
  }

  .more-info-title {
    @apply text-5xl mb-0 px-2;
  }

  .info-main-text {
    @apply w-full text-center mx-auto mb-6;
  }

  .bg-style-1 {
    @apply text-3xl bg-purple-app  text-white;
    height: fit-content;
  }

  .bg-style-2 {
    @apply text-4xl;
  }

  .bg-style-3 {
    @apply text-4xl;
  }

  .bg-style-4 {
    height: fit-content;
    @apply text-6xl;
  }

  .bg-style-5 {
    height: fit-content;
    @apply text-3xl;
  }

  .bg-style-6 {
    @apply text-5xl;
  }

  .on-left,
  .on-right {
    @apply mx-auto w-full;
  }
}
