/* src/Header.css */

/* General header styles */
.header {
  @apply bg-dark-purple-app text-white py-3 px-20 w-full z-50 bg-opacity-90;
  height: 5rem;
  z-index: 1;
}

/* Fixed header (aplicable solo en la LandingPage) */
.fixed-header {
  @apply fixed top-0 left-0;
}

/* Relative header (aplicable en otras vistas) */
.relative-header {
  @apply relative bg-opacity-100;
}

.logo-container {
  @apply text-2xl font-bold;
}

.logo-img {
  @apply h-14;
}

.nav {
  @apply space-x-6;
}

.nav-link {
  @apply text-white text-lg content-center tracking-tighter hover:text-orange-app;
}

.log-in-btn {
  @apply text-white text-lg border tracking-tighter rounded-lg py-1 px-3 hover:text-orange-app hover:border-orange-app;
}

.register-btn {
  @apply text-white text-lg border tracking-tighter border-orange-app bg-orange-app rounded-lg py-1 px-3 hover:bg-green-app hover:border-green-app  hover:text-white;
}

/* Sidebar styles */
.sidebar {
  @apply fixed top-0 right-0 h-full w-64 bg-purple-app text-white p-6 transform translate-x-full transition-transform duration-300 ease-in-out;
  z-index: 10;
}

.sidebar.open {
  @apply translate-x-0;
}

.close-button {
  @apply absolute top-4 right-4 text-2xl text-white bg-transparent border-none cursor-pointer;
}

/* Styles for sidebar navigation */
.sidebar .nav {
  @apply flex flex-col space-y-4;
}

/* Menu button for mobile */
.menu-button {
  @apply lg:hidden;
  background: none;
  border: none;
  cursor: pointer;
}

/* Responsive styles */
@media (min-width: 1024px) {
  .menu-button {
    @apply hidden;
  }

  .nav {
    @apply flex;
  }

  .sidebar {
    @apply hidden;
  }
}

@media (max-width: 1024px) {
  .header {
    @apply px-12;
  }
}
