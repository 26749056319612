/* src/Register.css */
.register-page {
  @apply flex flex-col items-center p-8 justify-center h-screen bg-purple-app text-white;
  height: calc(100vh - 5vh - 5rem);
}

.register-container {
  @apply bg-white rounded-lg shadow-lg text-center;
}

.register-title {
  @apply text-4xl font-bold text-purple-950 mb-4;
}

.register-description {
  @apply text-lg text-gray-700 mb-8;
}

.sign-out-btn {
  @apply bg-orange-app text-white font-bold py-2 px-4 rounded hover:bg-green-app transition duration-300;
}

@media (max-width: 1024px) {
  .register-page {
    min-height: calc(100vh - 20vh - 5rem);
    height: fit-content;
  }
}
