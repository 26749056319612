/* src/components/Footer.css */
.footer {
  @apply bg-dark-purple-app text-white py-4 tracking-tightest;
  height: 5vh;
}

.footer-content {
  @apply text-center text-sm;
}

@media (max-width: 1024px) {
    .footer {
        height: 20vh;
    }
}
